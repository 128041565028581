app.directive('dynAttr',['$compile','$sce', function ($compile, $sce) {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            list: '=?dynAttr',
            //uniqueID: '=uniqueId', printFromParam: '=printFromParam'
            moduleName: "=",
            menuKey: "=",
            categoryName: "=",
            columnName: "=",
            columnDs: "=",
            columnOptions: "=",
            operatorSymbol: "=",
            searchParam: "=",
            searchFromParam: "=",
            searchToParam: "=",
            ddlColumn: "=",
            //getColumnDataSource: "=",
            //getColumnOptions: "=",
            langKey:"=",
            label:"=",
            columnDataType: "=",
            columnFormat: "=",
            lmDataOptions: "=",
            lmDataFromOptions: "=",
            lmDataToOptions: "=",
            strFromOptions: "=",
            strToOptions: "=",
            strOptions: "=",
            lmIndex: "=",
            searchAndOr: "=",
            searchList: "=",
        },
        link: function (scope, elem, attrs) {
            var html = "";

            html = "<div "
            angular.forEach(scope.list, function (value, key) {
                ////console.log(key);
                ////console.log(value);
                if (value.value == "") {
                    html += value.attr + " ";
                } else {
                    html += value.attr + "=" + value.value + " ";
                }
            });

            html += " ></div>";
            //console.log(html);
            var el = angular.element(html),
                compiled = $compile(el);
                
            //append our view to the element of the directive.
            elem.append(el);

            //bind our view to the scope!
            compiled(scope);
        
        }
    };
}])